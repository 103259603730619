<template>
  <FieldSolutions/>
</template>

<script>
import FieldSolutions from './components/FieldSolutions.vue'

export default {
  name: 'App',
  components: {
    FieldSolutions
  }
}
</script>
