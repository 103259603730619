<template>
  <div id="download">
    <div class="header">
      <div class="leftDiv">
        <div class="logo">
          <img alt="" src="@/assets/image/download-icon-top-logo.png">
        </div>
        <div class="title">Field Solutions</div>
        <div class="description">
          Field Solutions, a SaaS-based software for inspection and audit services, is positioned to help clients to
          enhance the product quality and raise the market competitiveness thought unblocked insights with data
          collaboration. By leveraging digital technologies, on-site tasks will be free from dozen of paper, and data
          becomes traceable to raise supply chain visibility. Currently Field Solutions provides configurable report
          templates, covering hardlines, softlines and E&E business segments.
        </div>
        <div class="downLink">
          <div @click="openLink(1)">
            <img alt="" src="@/assets/image/download-icon-href1.png"> Download for
            iPhone
          </div>
          <div @click="openLink(2)">
            <img alt="" src="@/assets/image/download-icon-href2.png"> Download for
            Android
          </div>
        </div>
        <div class="downLink">
          <div @click="openLink(3)">
            <img alt="" src="@/assets/image/download-icon-href3.png"> Inspection PC
          </div>
          <div @click="openLink(4)">
            <img alt="" src="@/assets/image/download-icon-href4.png"> Assignment
            System
          </div>
        </div>
      </div>
      <div class="rightDiv">
        <ElCarousel
            ref="carousel"
            :indicator-position="'none'"
            :arrow="'never'"
            :interval="8000"
            @change="changeCallback">
          <ElCarouselItem>
            <div class="banner">
              <img alt="" src="@/assets/image/download-pic-banner1.png">
            </div>
          </ElCarouselItem>
          <ElCarouselItem>
            <div class="banner">
              <img alt="" src="@/assets/image/download-pic-banner2.png">
              <img
                  alt=""
                  src="@/assets/image/download-pic-banner-small.png"
                  class="small">
            </div>
          </ElCarouselItem>
        </ElCarousel>
      </div>
    </div>

    <div class="indicator">
      <span :class="selIndicator === 0 ? 'sel' : ''" @click="updIndicator(0)"/>
      <span :class="selIndicator === 1 ? 'sel' : ''" @click="updIndicator(1)"/>
    </div>

    <div class="typeSelection">
      <span
          :class="pageType === 1 ? 'sel' : ''"
          @click="updPageType(1)">Inspection Mobile</span>
      <span
          :class="pageType === 2 ? 'sel' : ''"
          @click="updPageType(2)">Inspection PC</span>
      <span
          :class="pageType === 3 ? 'sel' : ''"
          @click="updPageType(3)">Inspection Assignment system</span>
    </div>

    <div v-if="pageType === 1" class="mobileView">
      <div class="mobileFirst">
        <div class="details">
          <div>Inspection Sample Selection</div>
          <div>
            Integrate the BV sampling algorithm to automatically calculate the
            number of style sampling. Complete the collection and automatic
            naming of photos of the sampling process. In the box number
            confirmation module, the system integrates the OCR function to
            recognize the handwritten box number
          </div>
        </div>
        <div class="pic">
          <img alt="" src="@/assets/image/download-pic-mobile1.png">
        </div>
      </div>
      <div class="mobileSecond">
        <div class="pic">
          <img alt="" src="@/assets/image/download-pic-mobile2.png">
        </div>
        <div class="details">
          <div>Inspection Review Report</div>
          <div>
            Before the inspector leaves the site, he can review the draft
            report, preview the data and pictures of the entire inspection
            process, and submit it after confirmation. The system will push
            draft emails to the inspectors and factory mailboxes, record the
            departure time, and complete the inspection on the day
          </div>
        </div>
      </div>
      <div class="mobileLast">
        <div class="topText">
          <div>Field Solutions Inspection App More Features</div>
          <div>
            For more product introduction, please contact us to authorize
            download and use
          </div>
        </div>
        <div class="centerPic">
          <img alt="" src="@/assets/image/download-pic-mobile3.png">
        </div>
      </div>
    </div>

    <div v-if="pageType === 2" class="pcView">
      <div class="pcBlock picBg2">
        <div class="topText">
          <div>Inspector Dashboard</div>
          <div>
            Inspector workbench, better manage inspectors to handle inspection
            tasks, PC and Mobile work together, which will support the scene of
            multi-person inspection tasks
          </div>
        </div>
        <div class="centerPic">
          <img alt="" src="@/assets/image/download-pic-pc1.png">
        </div>
        <div class="font">
          <img alt="" src="@/assets/image/download-pic-font.png">
        </div>
      </div>
      <div class="pcBlock picBg">
        <div class="flexDiv">
          <div class="module">
            <div>Inspection Process</div>
            <div>
              The modular design of the inspection process, real-time display of
              multi-person system data
            </div>
          </div>
          <div class="pic">
            <img alt="" src="@/assets/image/download-pic-pc2.png">
          </div>
        </div>
      </div>
      <div class="pcBlockWhite">
        <div class="topText">
          <div>Field Solutions Inspection PC More Features</div>
          <div>
            For more product introductions, please get in touch with us to
            authorize the use
          </div>
        </div>
        <div class="centerPic">
          <img alt="" src="@/assets/image/download-pic-pc3.png">
        </div>
      </div>
    </div>

    <div v-if="pageType === 3" class="systemView">
      <div class="title">Field Solutions Inspection Assignment System</div>
      <div class="des">
        Provide basic data support for the inspection system, dispatch orders to
        inspectors, and use the Field Solutions Inspection system to complete
        the entire inspection from initiation to reporting generation. For more
        product introductions, please get in touch with us to authorize the us
      </div>
      <div class="pic">
        <img alt="" src="@/assets/image/download-pic-system.png">
      </div>
    </div>

    <div class="footer">
      <div class="form">
        <div class="title">Leave your information for us to contact you</div>
        <div class="line">
          <input v-model="query.companyName" placeholder="Company Name">
        </div>
        <div class="line">
          <input v-model="query.name" placeholder="Name">
        </div>
        <div class="line">
          <input v-model="query.email" placeholder="Email">
        </div>
        <div class="line">
          <input v-model="query.message" placeholder="Message">
        </div>
        <div class="sub" @click="sub()">Submit</div>
      </div>
      <div class="menuBlock">
        <div class="leftDiv">
          <div class="pic">
            <img alt="" src="@/assets/image/download-icon-bottom-logo.png">
          </div>
          <div class="link">
            <img alt="" src="@/assets/image/download-icon-link1.png">
            <img alt="" src="@/assets/image/download-icon-link2.png">
            <img alt="" src="@/assets/image/download-icon-link3.png">
          </div>
        </div>
        <div class="menu">
          <div class="menuTit">Menu</div>
          <div class="menuLine">Who we are</div>
          <div class="menuLine">Destinations</div>
          <div class="menuLine">Reviews</div>
          <div class="menuLine">Blog</div>
          <div class="menuLine">Contact</div>
        </div>
        <div class="menu">
          <div class="menuTit">Menu</div>
          <div class="menuLine">Who we are</div>
          <div class="menuLine">Destinations</div>
          <div class="menuLine">Reviews</div>
          <div class="menuLine">Blog</div>
          <div class="menuLine">Contact</div>
        </div>
        <div class="menu">
          <div class="menuTit">Menu</div>
          <div class="menuLine">Who we are</div>
          <div class="menuLine">Destinations</div>
          <div class="menuLine">Reviews</div>
          <div class="menuLine">Blog</div>
          <div class="menuLine">Contact</div>
        </div>
      </div>
      <div class="bottom">
        <span>Terms of service</span>
        <span>Privacy policy</span>
        <span>沪ICP备2024049947号-1</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue'
import {ElMessage} from 'element-plus'
import {ElCarousel} from 'element-plus'
import {ElCarouselItem} from 'element-plus'

const Message = ElMessage

const carousel = ref(null)

const pageType = ref(1)
const query = ref({
  companyName: '',
  name: '',
  email: '',
  message: ''
})
const selIndicator = ref(0)

const changeCallback = (num) => {
  selIndicator.value = num
}
const updIndicator = (num) => {
  carousel.value.setActiveItem(num)
}
const updPageType = (type) => {
  pageType.value = type
}
const sub = () => {
  if (query.value.companyName === '') {
    Message({
      message: 'Please Enter Company Name',
      type: 'warning',
      duration: 5 * 1000
    })
    return
  }
  if (query.value.name === '') {
    Message({
      message: 'Please Enter Name',
      type: 'warning',
      duration: 5 * 1000
    })
    return
  }
  if (query.value.email === '') {
    Message({
      message: 'Please Enter Email',
      type: 'warning',
      duration: 5 * 1000
    })
    return
  }
  if (query.value.message === '') {
    Message({
      message: 'Please Enter Message',
      type: 'warning',
      duration: 5 * 1000
    })
    return
  }

  query.value = {
    companyName: '',
    name: '',
    email: '',
    message: ''
  }
  ElMessage.success('Submit Successfully')
}
const openLink = (type) => {
  if (type === 1) {
    // window.open('https://www.pgyer.com/afsbeta', '_blank')
    window.open(process.env.VUE_APP_MOBILE_DOWN_LINK_IOS, '_blank')
  } else if (type === 2) {
    // window.open('https://www.pgyer.com/afsbeta', '_blank')
    window.open(process.env.VUE_APP_MOBILE_DOWN_LINK, '_blank')
  } else if (type === 4) {
    window.open(
        process.env.VUE_APP_WEB_ORDER_LINK,
        '_blank'
    )
  } else if (type === 3) {
    window.open(
        process.env.VUE_APP_WEB_INSPECTION_LINK,
        '_blank'
    )
  }
}
</script>

<style lang="scss" scoped>
#download {
  background: #ffffff;
  height: 100vh;
  overflow-y: auto;

  .typeSelection {
    padding-bottom: 25px;
    margin: 95px auto 60px;
    text-align: center;
    border-bottom: 1px solid #f1f1f1;

    span {
      display: inline-block;
      margin: 0 40px;
      font-size: 16px;
      color: #193a88;
      padding: 0 30px;
      border-radius: 48px;
      line-height: 48px;
      cursor: pointer;
      user-select: none;
      border: 1px solid #f1f1f1;
    }

    .sel {
      background: linear-gradient(235deg, #5b81de 0%, #173887 100%);
      color: #ffffff;
    }
  }

  .pcView {
    .movieView {;
      margin: 0 auto;

      video {
        width: 100%;
        height: 300px;
      }
    }

    .pcBlock {
      margin-top: 60px;
      background: linear-gradient(
              161deg,
              #173887 0%,
              #1a3b89 17%,
              #24438d 30%,
              #365295 43%,
              #4e66a1 55%,
              #6d80af 67%,
              #94a1c1 78%,
              #c1c7d6 89%,
              #f5f2ee 100%,
              #f7f4ef 100%
      );
      padding: 80px 0 120px;
      position: relative;

      .topText {
        margin: 0 auto;;
        text-align: center;

        div:nth-of-type(1) {
          font-size: 48px;
          color: #ffffff;
        }

        div:nth-of-type(2) {
          font-size: 18px;
          color: #c3c3c3;
          margin: 30px auto 60px;
          width: 1020px;
          word-break: break-all;
          line-height: 26px;
        }
      }

      .centerPic {
        font-size: 0;
        margin: 0 auto;
        width: 1024px;
        position: relative;
        z-index: 3;

        img {
          width: 100%;
        }
      }

      .flexDiv {;
        margin: 0 auto;
        position: relative;
        overflow: hidden;

        .pic {
          width: 780px;
          float: right;
          font-size: 0;

          img {
            width: 100%;
          }
        }

        .module {
          width: 500px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);

          div:nth-of-type(1) {
            font-size: 48px;
            color: #ffffff;
          }

          div:nth-of-type(2) {
            font-size: 18px;
            color: #c3c3c3;
            margin-top: 30px;
            line-height: 28px;
            word-break: break-all;
          }
        }
      }

      .font {
        position: absolute;
        bottom: 0;;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;

        img {
          width: 100%;
        }
      }
    }

    .picBg {
      /*background: none;*/
      background-image: url('../assets/image/download-pic-pc-background.png');
      background-size: 100% 100%;
    }

    .picBg2 {
      /*background: none;*/
      background-image: url('../assets/image/download-pic-pc-bgckground3.png');
      background-size: 100% 100%;
    }

    .pcBlockWhite {
      padding: 100px 0 60px;

      .topText {
        margin: 0 auto;;
        text-align: center;

        div:nth-of-type(1) {
          font-size: 48px;
          color: #0f398d;
        }

        div:nth-of-type(2) {
          font-size: 18px;
          color: #c3c3c3;
          margin: 32px auto 60px;
          width: 1010px;
          word-break: break-all;
          line-height: 30px;
        }
      }

      .centerPic {
        font-size: 0;
        margin: 0 auto;
        width: 1074px;

        img {
          width: 100%;
        }
      }
    }
  }

  .footer {
    /*background: linear-gradient(161deg, #173887 0%, #1A3B89 17%, #24438D 30%, #365295 43%, #4E66A1 55%, #6D80AF 67%, #94A1C1 78%, #C1C7D6 89%, #F5F2EE 100%, #F7F4EF 100%);*/
    background-image: url('../assets/image/download-pic-footer-background.png');
    background-size: 100% 100%;
    padding: 125px 0 110px;

    .form {
      background: #ffffff;
      box-shadow: 0 40px 80px 0 rgba(0, 0, 0, 0.3);
      border-radius: 18px;
      width: 970px;
      padding: 100px 0;
      margin: 0 auto;

      .title {
        color: #222a41;
        font-size: 24px;
        text-align: center;
        margin-bottom: 80px;
      }

      .line {
        margin: 50px 128px 0;
        border-bottom: 1px solid #aeb7c1;
        padding: 10px 20px;

        input {
          width: 100%;
          font-size: 15px;
          color: #222a41;
          height: 30px;
          line-height: 30px;
          border: none;
          outline: none;
        }
      }

      .sub {
        margin: 100px auto 0;
        width: 154px;
        height: 48px;
        background: linear-gradient(235deg, #5b81de 0%, #173887 100%);
        border-radius: 40px;
        text-align: center;
        line-height: 48px;
        color: #ffffff;
        font-size: 18px;
        cursor: pointer;
        user-select: none;
      }
    }

    .menuBlock {
      padding: 187px 176px 60px;
      overflow: hidden;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      .leftDiv {
        float: left;

        .pic {
          font-size: 0;
          margin-bottom: 60px;

          img {
            width: 162px;
          }
        }

        .link {
          overflow: hidden;

          img {
            float: left;
            width: 85px;
            height: 85px;
            border-radius: 50%;
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }

      .menu {
        float: right;
        margin-right: 15px;
        width: 250px;
        margin-top: 25px;

        .menuTit {
          color: #ffffff;
          font-size: 20px;
          margin-bottom: 30px;
        }

        .menuLine {
          color: #ffffff;
          font-size: 20px;
          line-height: 48px;
          cursor: pointer;
        }
      }
    }

    .bottom {
      color: #ffffff;
      font-size: 20px;
      text-align: center;
      margin-top: 35px;

      span {
        margin: 0 40px;
        cursor: pointer;
      }
    }
  }

  .header {
    padding: 60px 200px 0 180px;
    /*background: linear-gradient(161deg, #173887 0%, #1A3B89 17%, #24438D 30%, #365295 43%, #4E66A1 55%, #6D80AF 67%, #94A1C1 78%, #C1C7D6 89%, #F5F2EE 100%, #F7F4EF 100%);*/
    background-image: url('../assets/image/download-pic-header-background.png');
    background-size: 100% 100%;
    overflow: hidden;

    .leftDiv {
      float: left;

      .logo {
        font-size: 0;

        img {
          width: 160px;
        }
      }

      .title {
        margin-top: 160px;
        font-weight: 600;
        color: #ffffff;
        font-size: 80px;
      }

      .description {
        color: #ffffff;
        line-height: 27px;
        font-size: 18px;
        margin-top: 20px;
        /*width: 530px;*/
        width: 100%;
      }

      .downLink {
        margin-top: 30px;
        overflow: hidden;

        div {
          float: left;
          margin-right: 45px;
          height: 53px;
          background: #ffffff;
          border-radius: 3px;
          line-height: 53px;
          width: 200px;
          padding: 0 15px;
          color: #1f3f8b;
          font-size: 14px;
          cursor: pointer;
          user-select: none;

          img {
            width: 18px;
            margin-right: 8px;
            vertical-align: middle;
          }
        }

        div:nth-child(2n) {
          margin-right: 0;
        }
      }
    }

    .rightDiv {
      float: right;
      width: 840px;

      .banner {
        width: 840px;
        position: relative;

        img {
          width: 80%;
        }

        .small {
          position: absolute;
          right: 355px;
          bottom: 0;
          width: 65px;
        }
      }
    }
  }

  .mobileView {
    .mobileFirst {;
      margin: 0 auto 60px;
      background: linear-gradient(
              161deg,
              #173887 0%,
              #1a3b89 17%,
              #24438d 30%,
              #365295 43%,
              #4e66a1 55%,
              #6d80af 67%,
              #94a1c1 78%,
              #c1c7d6 89%,
              #f5f2ee 100%,
              #f7f4ef 100%
      );
      padding: 115px 80px 0;
      overflow: hidden;

      .details {
        width: 650px;
        float: left;
        margin-top: 120px;

        div:nth-of-type(1) {
          color: #ffffff;
          font-size: 48px;
        }

        div:nth-of-type(2) {
          color: #c3c3c3;
          font-size: 18px;
          width: 600px;
          margin-top: 30px;
          line-height: 28px;
        }
      }

      .pic {
        float: right;
        font-size: 0;
        margin-bottom: -25px;

        img {
          width: 580px;
        }
      }
    }

    .mobileSecond {;
      margin: 0 auto 100px;
      overflow: hidden;
      padding: 50px 100px 50px 120px;
      position: relative;
      background: linear-gradient(
              161deg,
              #173887 0%,
              #1a3b89 17%,
              #24438d 30%,
              #365295 43%,
              #4e66a1 55%,
              #6d80af 67%,
              #94a1c1 78%,
              #c1c7d6 89%,
              #f5f2ee 100%,
              #f7f4ef 100%
      );

      .pic {
        float: left;
        width: 330px;

        img {
          width: 100%;
        }
      }

      .details {
        width: 700px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 100px;

        div:nth-of-type(1) {
          color: #ffffff;
          font-size: 48px;
        }

        div:nth-of-type(2) {
          color: #c3c3c3;
          font-size: 18px;
          margin-top: 30px;
          line-height: 28px;
        }
      }
    }

    .mobileLast {;
      margin: 0 auto 80px;

      .topText {
        margin: 0 auto;
        text-align: center;

        div:nth-of-type(1) {
          font-size: 48px;
          color: #20184e;
        }

        div:nth-of-type(2) {
          font-size: 18px;
          color: #c3c3c3;
          margin: 30px auto 60px;
          width: 800px;
          word-break: break-all;
          line-height: 30px;
        }
      }

      .centerPic {
        font-size: 0;
        margin: 0 auto;

        img {
          width: 100%;
        }
      }
    }
  }

  .systemView {
    text-align: center;

    .title {
      font-size: 48px;
      color: #20184e;
    }

    .des {
      font-size: 18px;
      color: #c3c3c3;
      margin: 32px auto 42px;
      width: 900px;
    }

    .pic {
      font-size: 0;;
      margin: 0 auto 30px;

      img {
        width: 100%;
      }
    }
  }

  .indicator {
    text-align: center;
    margin-top: 20px;

    span {
      display: inline-block;
      width: 88px;
      height: 4px;
      margin: 0 8px;
      background: #f1f1f1;
      cursor: pointer;
    }

    .sel {
      background: #183987;
    }
  }
}
</style>

<style>
#download .el-carousel {
  height: 600px;
  width: 1440px;
}

#download .el-carousel__item {
  height: 0px;
}

</style>
